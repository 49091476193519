<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Experience</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2013 - 2018</span>
            <h3 class="title">Systems analyst and programmer</h3>
               XETID / La Habana /
      <ul>
        <li>
          Worked implementing modules and user interfaces in business
          management systems, decision support systems, as well as the
          development in geographic information systems.
        </li>
        <li>
          Responsible for API design and development of RESTful Services for
          the enterprise product in the business.
        </li>
        <li>
          Developed user interfaces with modern JavaScript frameworks,
          HTML5, and CSS3.
        </li>
        <li>
          Designed and developed web applications using JavaScript
          frameworks React, Vue and Angular
        </li>

      </ul>

          </div>
        </div>




        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2019-Present</span>
            <h3 class="title">Systems analyst and programmer </h3>
            DATYS / Santiago de Cuba / 2019-Present
            <ul>
              <li>
                 Developed object-oriented code in Node.js with 100% accordance to
                industry and company standards.
                </li>
              <li>
                - Worked within an agile frontend team whom did the implementations for
                enterprise systems.
               </li>
              <li>
                 Worked creating components for the management of the company using
                Angular as Javascript frameworks to create the user interfaces and material
                as design frameworks.
              </li>
              <li>
                Implemented integrated graphics to company and client applications
                using amcharts.
              </li>

            </ul>

          </div>
        </div>



        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>
