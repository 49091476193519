<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">About Me</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/avatar-2.svg" alt="Bolby" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p>
              Software engineer with 13+ years of experience building software
              applications, scalable web applications and internal tools that
              drastically improve efficiency. Good knowledge in user interface,
              testing, and debugging processes. Equipped with a diverse and promising
              skill-set. Able to effectively self-manage during independent projects, as
              well as collaborate in a team setting.

            </p>
            <div class="mt-3">
              <a href="javascript:" class="btn btn-default">Download CV</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Development</h4>
                <span class="float-right">85%</span>
              </div>
              <ngb-progressbar type="warning" [value]="85"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">UI/UX design</h4>
                <span class="float-right">95%</span>
              </div>
              <ngb-progressbar type="danger" [value]="95"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Photography</h4>
                <span class="float-right">70%</span>
              </div>
              <ngb-progressbar type="primary" [value]="70"></ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->

  <div class="spacer-70"></div>

  <div class="row">
    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">198</em></h3>
          <p class="mb-0">Projects completed</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-cup"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">5670</em></h3>
          <p class="mb-0">Cup of coffee</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">427</em></h3>
          <p class="mb-0">Satisfied clients</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-badge"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">35</em></h3>
          <p class="mb-0">Nominees winner</p>
        </div>
      </div>
    </div>
  </div>
</div>
